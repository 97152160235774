<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent
      title="Explosives Recognition and Decontamination"
      size="large"
    />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2"></div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph
          ><p>
            This 1-day course presents material relating to the recognition and
            safe handling of explosives, propellants, and reactive ingredients.
            During the course, relevant examples will be examined and discussed
            with an emphasis on recognition methods, desensitization, safe
            handling, and disassembly of contaminated equipment and materials.
            This training is tailored to personnel (managers, engineers, safety
            professionals etc.) involved with shut-down, decommissioning
            activities, decontamination efforts, explosive testing, facility
            oversite and remediation, or equipment handling. Participants will
            gain an understanding of the basic recognition of explosive hazards
            and will be equipped to safely handle energetic hazards encountered
            during decommissioning or shut-down processes.
          </p>
          <p>Course Content:</p>
          <ul>
            <li>Explosives and material safety issues</li>
            <li>Handling of contaminated equipment and materials</li>
            <li>Building inspection</li>
            <li>Activity plans</li>
            <li>Sampling methodologies</li>
            <li>Decontamination methods and applications</li>
            <li>Classification procedures</li>
            <li>Disposition procedures</li>
            <li>UN/DOT &amp; ATF hazard classification</li>
            <li>Disassembly procedures</li>
            <li>Container and package recognition</li>
            <li>PSM elements</li>
            <li>Employee participation</li>
            <li>Process safety information</li>
            <li>Training</li>
            <li>Emergency planning and response</li>
            <li>Documentation</li>
          </ul>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Explosives Recognition and Decontamination Course",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "This course presents material relating to the recognition and safe handling of explosives, propellants, and reactive ingredients."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.about {
}
</style>
